import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { storeToRefs } from 'pinia'
import { ITEM_SLIDER_KEY } from '@autobid/ui/constants/CAR_SLIDER_KEYS'
import { useQueryClient } from '@tanstack/vue-query'
import type { CarsListResponse } from '@autobid/ui/types/components/CarsList'
import type { ItemSliderProps } from '@autobid/ui/types/components/ItemSlider'

export const useUpdateWatch = () => {
  const { carsData } = storeToRefs(useCarsStore())
  const queryClient = useQueryClient()
  const SLIDER_TYPES: ItemSliderProps['type'][] = [
    'highlight',
    'recommendation'
  ]

  const update = (carId: number, value: boolean) => {
    const carExistsInStore = carsData.value?.items?.[carId] !== undefined

    if (carExistsInStore) {
      carsData.value.items[carId].isWatcher = value
    }

    for (const sliderType of SLIDER_TYPES) {
      const sliderData = queryClient.getQueryData([
        ITEM_SLIDER_KEY,
        sliderType
      ]) as CarsListResponse

      if (sliderData?.data?.items.items) {
        queryClient.setQueryData([ITEM_SLIDER_KEY, sliderType], {
          ...sliderData,
          data: {
            items: {
              items: sliderData.data.items.items.map((car) => {
                if (car.id !== carId) return car

                return {
                  ...car,
                  isWatcher: value
                }
              })
            }
          }
        })
      }
    }
  }

  return { update }
}
