<template>
  <CommonTooltip
    :tooltip="
      !watched ? $t('cars-list.observer-car') : $t('cars-list.unobserve-car')
    "
  >
    <DynamicAuctionItemListCarIcon
      :variant="watched ? 'primary' : 'border'"
      v-bind="$attrs"
      @click.stop="handleClick"
    >
      <span class="sr-only">
        {{
          !watched
            ? $t('cars-list.observer-car')
            : $t('cars-list.unobserve-car')
        }}
      </span>
      <CommonIconEye class="h-full w-full" />
    </DynamicAuctionItemListCarIcon>
  </CommonTooltip>
</template>

<script lang="ts" setup>
import type { WebapiResponse } from '@autobid/strapi-integration/typescript/Webapi'
import { getError } from '@autobid/strapi-integration/utils/getError'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'

interface Props {
  carId: number
  watched: boolean
}

const props = defineProps<Props>()
const emits = defineEmits(['update'])

const { t } = useI18n()
const { $customFetch } = useCustomFetch()
const push = usePush()

const loading = ref(false)

const handleClick = async () => {
  if (loading.value) return

  const queryMethod = props.watched ? 'DELETE' : 'POST'

  loading.value = true
  emits('update', !props.watched)

  try {
    await $customFetch<WebapiResponse>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'webapi',
        queryMethod,
        queryUrl: `/v1/cars/${props.carId}/watch`
      }
    })

    const messageKey =
      queryMethod === 'POST'
        ? 'cars-list.car-watch-success'
        : 'cars-list.car-unwatch-success'

    push.success(t(messageKey))
  } catch (err) {
    // restore previous value - was updated optimistically
    emits('update', !props.watched)

    const { message } = getError(err)
    push.error(message)
  }

  loading.value = false
}
</script>
